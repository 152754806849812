.form-control {
	margin-bottom: 1em;
	label {
		display: inline-block;

		margin-bottom: .5em;

		color: $text--hint;
	}
}
.help {
	font-size: .9em;
	font-style: italic;

	display: inline;

	color: $text--secondary;
}
.input{
	width: 100%;
	padding: .5em;

	transition: border-color 150ms linear;

	border: 1px solid $gray;
	border-radius: 4px;
	background-color: #FFF;
	&:hover{
		border-color: color($gray shade(15%));
	}
	&:active, &:focus{
		border-color: $accent;
		outline: 0;
	}
}
input, select{
	&[disabled] {
		cursor: not-allowed !important;
		background-color: $gray !important;
		color: $text--secondary !important;
	}
}
