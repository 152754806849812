@import 'lib/_utils.scss';
@import 'lib/_variables.scss';
@import 'lib/_reset.scss';
@import 'lib/_form.scss';
main {
	max-width: 600px;
	margin-top: 10em;
	h1 {
		text-align: center;

		color: $primary;
	}
	section {
		margin-bottom: 2em;
		@mixin card 1, true;
		> p {
			color: $text--secondary;
		}
		a {
			text-decoration: none;
			border-bottom: 1px dotted $accent;
			color: $text--secondary;
			transition: color 150ms linear;
			&:hover {
				color: $accent;
			}
		}
	}
	.act {
		text-align: right;
		#error {
			margin-right: 0.5em;

			color: $red;
		}
		a {
			margin-right: 0.5em;
			@mixin button-default;
		}
		button {
			@mixin button-accent 1, true;
		}
	}
}
@media (--xs) {
	main {
		margin-top: 4em;
		section {
			margin-bottom: 1em;
		}
	}
}
