@define-mixin shadow $depth: 1, $transition: false{
	@if $depth == 1{
		box-shadow: 0 10px 10px -10px rgba(50,50,93,.12), 0 1px 3px rgba(0,0,0,.08);
	} @else{
		@if $depth == 2{
			box-shadow: 0 10px 10px -10px rgba(50,50,93,.16), 0 4px 9px rgba(0,0,0,.1);
		} @else{
			box-shadow: 0 12px 10px -10px rgba(50,50,93,.16), 0 4px 9px rgba(0,0,0,.15);
		}
	}
	@if $transition == true{
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		&:hover, &:focus{
			@if $depth <= 1{
				box-shadow: 0 10px 10px -10px rgba(50,50,93,.16), 0 4px 9px rgba(0,0,0,.1);
			} @else{
				@if $depth == 2{
					box-shadow: 0 12px 10px -10px rgba(50,50,93,.16), 0 4px 9px rgba(0,0,0,.15);
				} @else{
					box-shadow: 0 14px 10px -10px rgba(50,50,93,.16), 0 6px 9px rgba(0,0,0,.17);
				}
			}
		}
	}
}
@define-mixin card $depth: 0, $transition: false{
	padding: 1em 2em;

	border-radius: 4px;
	background-color: #fff;
	h2{
		font-weight: 300;

		margin-bottom: 1em;

		color: $text--secondary;
	}
	@if $depth == 0{
		@mixin shadow 1, false;
	} @else{
		@mixin shadow $depth, $transition;
	}
}
@define-mixin button{
	padding: .5em 1em;

	cursor: pointer;
	text-decoration: none;

	border: none;
	border-radius: 4px;
	&:focus{
		outline: none;
	}
	&[disabled] {
		cursor: default !important;

		color: $text--hint !important;
		&, &:hover, &:active{
			transform: translateY(0);

			box-shadow: none;
		}
	}
}
@define-mixin button-pop{
	&:hover, &:focus{
		transform: translateY(-1px);
	}
	&:active{
		transform: translateY(1px);
	}
}
@define-mixin button-style $depth, $pop, $size, $bg, $bg-hover, $bg-active, $cl: color(#fff a(90%)), $cl-hover: #fff, $cl-active: #fff{
	font-weight: 600;
	line-height: 1.6;

	letter-spacing: .025em;

	color: $cl;
	background-color: $bg;
	@mixin button;
	@mixin shadow $depth, true;
	@if $pop == true{
		@mixin button-pop;
	}
	@if $size == 0{
		font-size: .8em;
	}@else{
		@if $size == 1{
			font-size: 1em;
		}@else{
			font-size: 1.2em;
		}
	}
	&:hover, &:focus{
		color: $cl-hover;
		background-color: $bg-hover;
	}
	&:active{
		color: $cl-active;
		background-color: $bg-active;
		box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	}
	&[disabled] {
		color: $text-white--secondary !important;
		&, &:hover{
			background-color: color($bg tint(25%));
		}
	}
}

@define-mixin button-default $depth: 1, $pop: false, $size: 1{
	@mixin button-style $depth, $pop, $size, #fff, #fff, $gray--light, $primary, $primary--light, $primary--dark;
}
@define-mixin button-default--accent $depth: 1, $pop: false, $size: 1{
	@mixin button-style $depth, $pop, $size, #fff, #fff, $gray--light, $accent, $accent--light, $accent--dark;
}
@define-mixin button-primary $depth: 1, $pop: false, $size: 1{
	@mixin button-style $depth, $pop, $size, $primary, $primary--light, $primary--dark;
}
@define-mixin button-accent $depth: 1, $pop: false, $size: 1{
	@mixin button-style $depth, $pop, $size, $accent, $accent--light, $accent--dark;
}
@define-mixin button-danger $depth: 1, $pop: false, $size: 1{
	@mixin button-style $depth, $pop, $size, $red, $red--light, $red--dark;
}
